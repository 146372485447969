import { Card, CardBody } from "@material-tailwind/react";

import Chart from "react-apexcharts";

export default function LineChart({ todayData, yesterdayData, categories }) {
  const chartConfig = {
    type: "line",
    height: 240,
    widht: 380,

    series: [
      {
        name: "Today",
        data: todayData,
        // data: [50, 200, 100, 300, 500],
      },
      {
        name: "Yesterday", // Name of the second line
        data: yesterdayData,
        // data: [100, 100, 200, 100, 400], // Data points for the second line
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#ffb300", "#323226"],
      stroke: {
        lineCap: "round",
        curve: "smooth",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
        categories: categories,
      },
      yaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      legend: {
        show: false, // Hide legends
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 1,
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 5,
          right: 20,
        },
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  return (
    <Card className="shadow-none p-0">
      <CardBody className="p-0">
        <Chart {...chartConfig} />
      </CardBody>
    </Card>
  );
}
