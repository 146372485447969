import { useEffect, useState } from "react";
import {
  Navbar,
  Button,
  Typography,
  Card,
  MenuItem,
} from "@material-tailwind/react";
import Logo from "../Components/Logo";
import { DrawerWithNavigation } from "../Components/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import LineChart from "../Components/LineChart";
import Footer from "../Components/Footer";
import LimitChart from "../Components/LimitChart";
import DoubleBarChart from "../Components/BarChart";
import { useCookies } from "react-cookie";
import { API_URL } from "../constants";

const weeklyData = [
  { thisWeek: 0, lastWeek: 0 },
  { thisWeek: 0, lastWeek: 0 },
  { thisWeek: 0, lastWeek: 0 },
  { thisWeek: 0, lastWeek: 0 },
  { thisWeek: 0, lastWeek: 0 },
  { thisWeek: 0, lastWeek: 0 },
  { thisWeek: 0, lastWeek: 0 },
];

const monthlyData = [
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
  { thisMonth: 0, lastMonth: 0 },
];

const Dashboard = () => {
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [weekly, setWeekly] = useState(true);
  const [todayData, setTodayData] = useState([]);
  const [yesterdayData, setYesterdayData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [todayUnits, setTodayUnits] = useState(0);

  const [cookies] = useCookies(["consumer_uuid"]);

  useEffect(() => {
    if (!cookies?.consumer_uuid) {
      window.location.href = "/accounts/sign-in";
    }
  }, [cookies]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // For today
        const res = await fetch(
          `${API_URL}/analytics/fetch?meter_id=${cookies?.meter_id}&duration=today`
        );

        // For yesterday
        const res1 = await fetch(
          `${API_URL}/analytics/fetch?meter_id=${cookies?.meter_id}&duration=yesterday`
        );

        // For Current year
        const res2 = await fetch(
          `${API_URL}/analytics/fetch?meter_id=${cookies?.meter_id}&duration=current_year`
        );

        // For last year
        const res3 = await fetch(
          `${API_URL}/analytics/fetch?meter_id=${cookies?.meter_id}&duration=last_year`
        );

        // Data of each requests
        const data = await res.json();
        const data2 = await res1.json();
        const data3 = await res2.json();
        const data4 = await res3.json();
        console.log(data3);
        console.log(data);
        // Temp arrays to assign to the states
        let d = [];
        let d1 = [];
        let c = [];
        console.log(data3);
        if (data.length > 0) {
          data.forEach((entry) => {
            if (entry[3] !== -1) {
              setTodayUnits(entry[3]);
              d.push(entry[3]);
              let date = new Date(entry[0]);
              let hours = String(date.getHours()).padStart(2, "0");
              let minutes = String(date.getMinutes()).padStart(2, "0");
              c.push(`${hours}:${minutes}`);
            }
          });
        }
        if (data2.length > 0) {
          data2.forEach((entry) => {
            if (entry[3] !== -1) {
              d.push(entry[3]);
              let date = new Date(entry[0]);
              let hours = String(date.getHours()).padStart(2, "0");
              let minutes = String(date.getMinutes()).padStart(2, "0");
              c.push(`${hours}:${minutes}`);
            }
          });
        }

        // Making weeklyData Array
        // Get current date
        let now = new Date();

        // Get Monday of this week
        let mondayThisWeek = new Date(
          now.setDate(now.getDate() - now.getDay() + 1)
        );
        mondayThisWeek.setHours(0, 0, 0, 0);

        // Get Monday of last week
        let mondayLastWeek = new Date(mondayThisWeek);
        mondayLastWeek.setDate(mondayThisWeek.getDate() - 7);

        // Get Sunday of this week
        let sundayThisWeek = new Date(mondayThisWeek);
        sundayThisWeek.setDate(mondayThisWeek.getDate() + 6);

        // Get Sunday of last week
        let sundayLastWeek = new Date(mondayLastWeek);
        sundayLastWeek.setDate(mondayLastWeek.getDate() + 6);

        data3.forEach((entry) => {
          let date = new Date(entry[0]);
          if (entry[3] !== -1) {
            if (isInRange(date, mondayLastWeek, sundayLastWeek)) {
              weeklyData.slice(date.getDay() - 1)[0].lastWeek += entry[3];
            } else if (isInRange(date, mondayThisWeek, sundayThisWeek)) {
              weeklyData.at(date.getDay() - 1).thisWeek += entry[3];
            }
          }
        });

        // Making monthlyData Array
        for (let i = 0; i < data3.length; i++) {
          let date = new Date(data3[i][0]);
          if (data3[i][3] !== -1) {
            monthlyData[date.getMonth()].thisMonth += data3[i][3];
          }
        }

        for (let i = 0; i < data4.length; i++) {
          let date = new Date(data4[i][0]);
          if (data4[i][3] !== -1) {
            monthlyData[date.getMonth()].lastMonth += data4[i][3];
          }
        }

        setTodayData(d);
        setYesterdayData(d1);
        setCategories(c);
      } catch (error) {
        console.log(error.message);
      }
    };

    return () => fetchData();
  }, [cookies]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setScrolled(window.scrollY !== 0);
    });
  }, [scrolled]);

  const consumption = 822; // User's consumption for the current month
  const limit = 1000; // Limit set by the user
  return (
    <>
      <Navbar
        className="sticky top-0 z-50 px-4 py-2 border-b border-gray-300 text-gray-900"
        shadow={scrolled}
        fullWidth
      >
        <div className="flex items-center justify-between">
          <div className="flex justify-center">
            <Button
              ripple={false}
              variant="text"
              className="active:bg-transparent hover:bg-transparent"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <FontAwesomeIcon className="text-base" icon={faBars} />
            </Button>
            <Logo />
          </div>
        </div>
      </Navbar>

      <DrawerWithNavigation open={open} setOpen={setOpen} />
      <main onClick={() => setOpen(false)}>
        <div className="w-10/12 mx-auto mb-12  ">
          <Typography
            variant="h4"
            className="mt-6 font-semibold text-[#323226] "
          >
            TODAY
          </Typography>
          <div className="flex lg:flex-row flex-col">
            <div className="w-full mr-0 lg:mr-6 lg:w-4/12 flex flex-row lg:flex-col justify-between">
              <div className=" w-10/12 rounded-md w-full mt-6 h-36 lg:h-40 border-2 border-[#323226] flex  flex-col justify-center items-center ">
                <Typography
                  variant="h5"
                  style={{ fontSize: "clamp(16px, 2vw, 22px)" }}
                  className="font-semibold text-[#323226] "
                >
                  Units Consumed
                </Typography>
                <Typography
                  variant="h3"
                  className="tracking-wider"
                  color="amber"
                >
                  {todayUnits}
                </Typography>
                <Typography
                  variant="h4"
                  className="font-semibold text-[#323226] "
                >
                  kWh
                </Typography>
              </div>
              <div className="mt-6 ml-9 lg:mt-0 lg:ml-0 self-end lg:self-start">
                <Typography
                  variant="h6"
                  className="font-semibold text-[#323226] "
                >
                  LEGEND
                </Typography>
                <Typography className="flex items-center">
                  <span className="w-4 h-4 bg-[#ffb300] rounded-md mr-2"></span>
                  Today
                </Typography>
                <Typography className="flex items-center">
                  <span className="w-4 h-4 bg-[#323226] rounded-md mr-2"></span>
                  Yesterday
                </Typography>
              </div>
            </div>
            <div className=" mt-6 flex-1 border-2 border-[#323226] rounded-md p-4">
              <LineChart
                todayData={todayData}
                yesterdayData={yesterdayData}
                categories={categories}
              />
            </div>
          </div>
        </div>
        <hr />

        <div className="w-10/12 mx-auto mb-12">
          <div className="flex flex-col-reverse lg:flex-row-reverse ">
            <div className="rounded-md mt-6 w-full lg:w-3/12  flex flex-col justify-between ">
              <Typography
                variant="h4"
                className="mt-6 font-semibold text-[#323226] "
              >
                LIMIT
              </Typography>
              <LimitChart consumption={consumption} limit={limit} />
            </div>
            <div className=" mt-6 flex-1  rounded-md ">
              <Typography
                variant="h4"
                className="mt-6 font-semibold text-[#323226] mb-6 "
              >
                HISTORY
              </Typography>
              <Card className="shadow-none bg-gray-100 ">
                <div className="flex gap-2 w-40 mt-2 ml-4">
                  <MenuItem
                    className={`${weekly ? "bg-gray-200" : "bg-transparent"}`}
                    onClick={() => setWeekly(true)}
                  >
                    Weekly
                  </MenuItem>
                  <MenuItem
                    className={`${weekly ? "bg-transparent" : "bg-gray-200"}`}
                    onClick={() => setWeekly(false)}
                  >
                    Monthly
                  </MenuItem>
                </div>
                {weekly ? (
                  <DoubleBarChart data={weeklyData} type="week" />
                ) : (
                  <DoubleBarChart data={monthlyData} type="month" />
                )}
              </Card>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Dashboard;

// Function to check if a date is in a given range
const isInRange = (date, start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const currentDate = new Date(date);

  const dateOnly = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate());

  const current = dateOnly(currentDate);
  const startOnly = dateOnly(startDate);
  const endOnly = dateOnly(endDate);
  return current >= startOnly && current <= endOnly;
};
