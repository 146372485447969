import React from "react";
import Chart from "react-apexcharts";

const LimitChart = ({ consumption, limit }) => {
  const chartData = {
    series: [consumption, Math.max(0, limit - consumption)],
    options: {
      chart: {
        type: "donut",
      },
      labels: [
        `Consumed (${consumption} kWh)`,
        `Remaining (${limit - consumption} kWh)`,
      ],
      colors: ["#ffca28", "#323226"],
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height={350}
      />
    </div>
  );
};

export default LimitChart;
