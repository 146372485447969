import React, { useEffect, useState } from "react";

import { Card, Typography, Radio, Button, Input } from "@material-tailwind/react";

import Logo from "../Components/Logo";
import { TextLink } from "../Components/Typography";
import { faEye, faEyeLowVision } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { API_URL } from "../constants";
import { useCookies } from "react-cookie";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SignInPage = () => {
    const [cookies] = useCookies(["consumer_uuid"]);
    useEffect(() => {
        if (cookies?.consumer_uuid) {
            window.location.href = "/dashboard";
        }
    }, [cookies]);

    const availableRoles = [
        // {
        //     label: "Administrator",
        //     value: "administrator",
        //     description: "Account owner that performs tasks requiring unrestricted access.",
        // },
        {
            label: "Standard",
            value: "standard",
            description: "User within an account that performs daily tasks.",
        },
    ];

    const [selectedRole, setSelectedRole] = useState(availableRoles[0].value);
    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const RoleSelector = ({ name, availableRoles }) => {
        return (
            <div className="flex flex-col gap-4">
                {availableRoles.map((role, index) => (
                    <Card
                        shadow={false}
                        className={`p-2 border-2 ${
                            selectedRole === role.value ? "border-amber-500 bg-[#fffdf5]" : "border-gray-200"
                        }`}
                        key={index}
                    >
                        <Radio
                            name={name}
                            value={role.value}
                            checked={selectedRole === role.value}
                            onChange={handleRoleChange}
                            label={
                                <>
                                    <Typography className="font-medium text-gray-800">{role.label}</Typography>
                                    <Typography variant="small" className="font-normal text-gray-700">
                                        {role.description}
                                    </Typography>
                                </>
                            }
                            color="amber"
                            containerProps={{
                                className: "-mt-4",
                            }}
                        />
                    </Card>
                ))}
            </div>
        );
    };

    const UserForm = () => {
        const [cookies, setCookies] = useCookies(["consumer_uuid", "meter_id"]);

        useEffect(() => {
            if (cookies?.consumer_uuid) {
                window.location.href = "/dashboard";
            }
        }, [cookies]);

        const [email, setEmail] = useState("");
        const [emailError, setEmailError] = useState("");

        const [password, setPassword] = useState("");
        const [passwordError, setPasswordError] = useState("");

        const [isFormValid, setIsFormValid] = useState(false);

        const handleEmailChange = (event) => {
            setEmail(event.target.value);
        };

        const handlePasswordChange = (event) => {
            setPassword(event.target.value);
        };

        const resetForm = () => {
            setEmail("");
            setPassword("");
        };

        const [isLoading, setLoading] = useState(false);

        const [showPassword, setShowPassword] = useState(false);
        const handleTogglePasswordVisibility = () => {
            setShowPassword(!showPassword);
        };

        useEffect(() => {
            const ValidateForm = () => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                let isValid = true;

                if (!emailRegex.test(email) && email?.length) {
                    setEmailError("Enter a valid email address");
                    isValid = false;
                } else {
                    setEmailError("");
                }

                if (!email?.length || !password?.length) {
                    isValid = false;
                }

                setIsFormValid(isValid);
            };

            ValidateForm();
        }, [email, password]);

        const handleSubmit = async (e) => {
            e.preventDefault();

            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/auth/sign-in`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: email, password: password }),
                });

                if (!response.ok) {
                    if (response.status === 404) {
                        setEmailError("User not found");
                    } else if (response.status === 401) {
                        setPasswordError("Invalid email or password");
                    }
                    throw new Error("Authentication Failed");
                }

                const tokens = await response.json();
                console.log(tokens);

                setCookies("consumer_uuid", tokens.consumer_uuid, { path: "/" });
                setCookies("meter_id", tokens.meter_id, { path: "/" });

                window.location.href = "/dashboard";

                resetForm();
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };

        return (
            <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
                <fieldset className="flex flex-col gap-3" disabled={isLoading}>
                    <div className="flex flex-col gap-1">
                        <Typography className="text-xs font-semibold">Email</Typography>
                        <Input
                            size="md"
                            placeholder="name@mail.com"
                            className="!border-t-blue-gray-200 focus:!border-t-amber-500"
                            color="amber"
                            value={email}
                            onChange={handleEmailChange}
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        {emailError && <p className="text-red-500 text-xs">{emailError}</p>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <Typography className="text-xs font-semibold">Password</Typography>
                        <div className="relative">
                            <Input
                                placeholder="********"
                                className=" !border-t-blue-gray-200 focus:!border-t-amber-500"
                                color="amber"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={handlePasswordChange}
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                            <button
                                type="button"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                onClick={handleTogglePasswordVisibility}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeLowVision : faEye} />
                            </button>
                        </div>
                        {passwordError && <p className="text-red-500 text-xs">{passwordError}</p>}
                        <Typography className="text-right">
                            <p className="font-medium text-xs text-amber-500 hover:text-amber-600 active:text-amber-700 hover:underline">
                                Forgot password?
                            </p>
                        </Typography>
                    </div>
                </fieldset>
                <Button color="amber" type="submit" fullWidth disabled={isLoading || !isFormValid}>
                    {!isLoading ? (
                        "Sign In"
                    ) : (
                        <div className="flex justify-center items-center gap-1 h-[16px]">
                            <FontAwesomeIcon icon={faSpinner} size="xl" className="animate-spin" />
                        </div>
                    )}
                </Button>
            </form>
        );
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center px-4 md:px-16 py-8">
            <Card
                shadow={false}
                className="w-full md:w-fit max-w-md flex flex-col items-center gap-6 px-8 py-12 md:border md:border-gray-300"
            >
                <div>
                    <Logo />
                </div>
                <div className="flex flex-col gap-4">
                    <Typography className="font-light text-center text-gray-800">Sign In</Typography>
                </div>
                <div className="w-full md:w-96 flex flex-col gap-6 transition-all duration-400">
                    <RoleSelector name="userRole" availableRoles={availableRoles} />
                    <UserForm />
                </div>
                <div>
                    <Typography variant="small">
                        Don't have an account? <TextLink href="/accounts/sign-up">Sign up</TextLink>
                    </Typography>
                </div>
            </Card>
        </div>
    );
};

export default SignInPage;
