import Chart from "react-apexcharts";
let current;
let previous;

const DoubleBarChart = ({ data, type }) => {
  // Data for the chart

  if (type === "week") {
    current = data.map((d) => d.thisWeek);
    previous = data.map((d) => d.lastWeek);
  } else {
    current = data.map((d) => d.thisMonth);
    previous = data.map((d) => d.lastMonth);
  }

  const series = [
    {
      name: `${data.length === 7 ? "This Week" : "This Year Month"}`,
      data: current,
    },
    {
      name: `${data.length === 7 ? "Last Week" : "Last Year Month"}`,
      data: previous,
    },
  ];

  let categories;
  // X-axis categories
  if (type === "week") {
    categories = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];
  } else {
    categories = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  }

  // Chart options
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar with download menu buttons
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Units",
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " units";
        },
      },
    },
    colors: ["#ffb300", "#323226"],
  };

  return (
    <div className="double-bar-chart">
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

export default DoubleBarChart;
