import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Drawer, Button, List, ListItem, ListItemPrefix } from "@material-tailwind/react";

export function DrawerWithNavigation({ open, setOpen }) {
    const [cookies, setCookies] = useCookies(["consumer_uuid", "meter_id"]);
    const handleLogout = () => {
        setCookies("consumer_uuid", "", { path: "/" });
        setCookies("meter_id", "", { path: "/" });

        console.log(cookies);

        window.location.href = "/accounts/sign-in";
    };

    return (
        <>
            <Drawer
                overlay={false}
                className="top-auto"
                overlayProps={{ className: "fixed inset-0 z-50 bg-black opacity-50" }}
                open={open}
                onClose={() => setOpen(open)}
            >
                <List className="mt-2 mx-0 !min-w-full">
                    <Link to="/dashboard">
                        <ListItem
                            ripple={false}
                            className="hover:!bg-transparent focus:!bg-transparent hover:translate-x-1"
                        >
                            <ListItemPrefix>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.04 16.5l.5-1.5h6.42l.5 1.5H8.29zm7.46-12a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-3 2.25a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9zm-3 2.25a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </ListItemPrefix>
                            Dashboard
                        </ListItem>
                    </Link>
                    <hr className="w-full" />
                    <Link to="/predictions">
                        <ListItem className="hover:!bg-transparent focus:!bg-transparent hover:translate-x-1">
                            <ListItemPrefix>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </ListItemPrefix>
                            Prediction
                        </ListItem>
                    </Link>
                    <hr className="w-full" />
                    <Link to="/testing">
                        <ListItem className="hover:!bg-transparent focus:!bg-transparent hover:translate-x-1">
                            <ListItemPrefix>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="h-5 w-5"
                                >
                                    <path d="M16 16v4m-8-4l-3 3-3-3m3 3v-10" />
                                    <path d="M12 2v10m0 0l-3-3m3 3l3-3m-3 3v10" />
                                </svg>
                            </ListItemPrefix>
                            Testing
                        </ListItem>
                    </Link>
                </List>
                <Button className="mt-3 ml-5" size="sm" onClick={handleLogout}>
                    Logout
                </Button>
            </Drawer>
        </>
    );
}
