import { useState, useEffect } from "react";
import { Navbar, Typography, Card, Button, Input } from "@material-tailwind/react";
import Logo from "../Components/Logo";
import { DrawerWithNavigation } from "../Components/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUpload, faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../constants";
import { useCookies } from "react-cookie";

const ImageTesting = () => {
    const [scrolled, setScrolled] = useState(false);
    const [open, setOpen] = useState(false);

    const [x, setX] = useState("");
    const [y, setY] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [ocrResult, setOcrResult] = useState(null);

    const [cookies] = useCookies(["consumer_uuid", "meter_id"]);
    
    useEffect(() => {
        if (!cookies?.consumer_uuid) {
            window.location.href = "/accounts/sign-in";
        }
    }, [cookies])

    useEffect(() => {
        document.addEventListener("scroll", () => {
            setScrolled(window.scrollY !== 0);
        });
    }, [scrolled]);

    useEffect(() => {
        setOcrResult(null)
    }, [selectedFile])

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handlePost = async () => {
        try {
            const file = selectedFile;
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                const formData = {
                    consumer_uuid: cookies?.consumer_uuid,
                    meter_id: cookies?.meter_id,
                    image: base64String,
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                    date: date,
                    time: time,
                };
                fetch(`${API_URL}/resources/camera/stream`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log("data: ", data);
                        setOcrResult(data.ocr);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        } catch (error) {
            // Handle any errors during the request
            console.error("Error posting data:", error);
        }
    };

    return (
        <>
            <Navbar
                className="sticky top-0 z-50 px-4 py-2 border-b border-gray-300 text-gray-900"
                shadow={scrolled}
                fullWidth
            >
                <div className="flex items-center justify-between">
                    <div className="flex justify-center">
                        <Button
                            ripple={false}
                            variant="text"
                            className="active:bg-transparent hover:bg-transparent"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <FontAwesomeIcon className="text-base" icon={faBars} />
                        </Button>
                        <Logo />
                    </div>
                </div>
            </Navbar>

            <DrawerWithNavigation open={open} setOpen={setOpen} />

            <div className="container mx-auto mt-4">
                <Typography className="text-2xl font-bold mb-4">Image Testing Page</Typography>
                <div className="grid grid-cols-2 gap-4 p-4">
                    <Input
                        placeholder=""
                        color="amber"
                        size="md"
                        type="number"
                        value={x}
                        onChange={(e) => setX(e.target.value)}
                        label="X"
                    />
                    <Input
                        placeholder=""
                        color="amber"
                        size="md"
                        type="number"
                        value={y}
                        onChange={(e) => setY(e.target.value)}
                        label="Y"
                    />
                    <Input
                        placeholder=""
                        color="amber"
                        size="md"
                        type="number"
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                        label="Width"
                    />
                    <Input
                        placeholder=""
                        color="amber"
                        size="md"
                        type="number"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        label="Height"
                    />
                    <Input
                        placeholder="YYYY-MM-DD"
                        color="amber"
                        size="md"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        label="Date"
                    />
                    <Input
                        placeholder="HH:MM:SS"
                        color="amber"
                        size="md"
                        type="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        label="Time"
                    />
                </div>
            </div>

            {ocrResult && (
                <div className="container mx-auto mt-4">
                    <Card className="bg-white shadow-md rounded-lg px-6 py-4">
                        <Typography className="text-2xl font-bold mb-4 text-gray-800">
                            OCR Result
                        </Typography>
                        <div className="flex justify-between">
                            <Typography className="text-lg text-gray-700">
                                Value:
                            </Typography>
                            <Typography className="text-lg text-blue-700">
                                {ocrResult?.value}
                            </Typography>
                        </div>
                        <div className="flex justify-between mt-2">
                            <Typography className="text-lg text-gray-700">
                                Confidence:
                            </Typography>
                            <Typography className="text-lg text-blue-700">
                                {ocrResult?.confidence}
                            </Typography>
                        </div>
                    </Card>
                </div>
            )}

            <div className="container mx-auto mt-10">
                <Card>
                    <label htmlFor="upload" className="cursor-pointer">
                        <div className="flex flex-col items-center justify-center space-y-4 p-8">
                            {!selectedFile && (
                                <>
                                    <FontAwesomeIcon icon={faUpload} className="text-5xl text-gray-500" />
                                    <Typography color="gray">Upload Image</Typography>
                                </>
                            )}
                            {selectedFile && (
                                <div className="relative">
                                    <img
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="Uploaded"
                                        className="max-w-full h-auto max-h-60"
                                    />
                                    <button
                                        className="absolute top-0 right-0 mt-2 mr-2 bg-white rounded-full p-1"
                                        onClick={() => setSelectedFile(null)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} className="text-red-500" />
                                    </button>
                                </div>
                            )}
                            <input
                                type="file"
                                id="upload"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="hidden"
                            />
                        </div>
                    </label>
                </Card>
                <div className="flex justify-end p-4">
                    <Button color="amber" onClick={handlePost}>
                        <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                        Post
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ImageTesting;
